
import { mapState } from 'vuex';

import { Component, Prop, Watch } from 'vue-property-decorator';

import { OrderActivityInterface } from '@/interfaces/Order';

import { AccommodationsRoomTypeParams } from '@/interfaces/Hotels';

import { localStorage } from '@/config/localStorage';

import { BookingsApi } from '@/api/BookingsApi';

import { HotelsApi } from '@/api/HotelsApi';

import { ActivitiesApi } from '@/api/ActivitiesApi';

import { elementVisible } from '@/config/functions';

import { first, find, isNull, mapValues, sortBy, uniq } from 'lodash';

// @ts-ignore
import colorString from 'color-string';

import { MetaInfo } from 'vue-meta';

import b64toBlob from 'b64-to-blob';

import { downloadBlob } from 'download.js';

import md5 from 'md5';

import jsPDF from 'jspdf';

import '@/config/Roboto-normal';

import autoTable from 'jspdf-autotable';

import { colorTooltipsMixin } from '@/mixins/colorTooltipsMixin.js';

// @ts-ignore
import Time from '@flat/time';

import BasePage from '@/components/BasePage';

const currencies = mapValues(require('currency-formatter/currencies.json'), (c: any) => ({
   ...c,
   symbolPosition: c.symbolOnLeft ? 'front' : '',
   fractionCount: c.decimalDigits,
}));

@Component({
   components: {
      ActionCreateReport: () => import('@/views/activity/action/_create-report.vue'),
      ActionCreateInvoice: () => import('@/views/activity/action/_create_invoice.vue'),
   },
   computed: {
      ...mapState({
         guide_services: (s: any) => s.activities.services,
         currencies: (s: any) => s.activities.currencies,
         tarification: (s: any) => s.activities.tarification,
         activities: (s: any) => s.activities.items,
         user_roles: (s: any) => s.users.roles,
         activities_colors: (s: any) => sortBy(s.activities.colors, 'd_sort'),
         payment_colors: (s: any) => sortBy(s.activities.paymentColors, 'd_sort'),
         hotel_rooms(s: any) {
            const hotel_rooms: any[] = s.activities.rooms;
            if (first(hotel_rooms)?.id) {
               hotel_rooms.unshift({ id: '', o_beds: '', o_cost: 0, o_group_of_hotels: '', o_name: '', o_room: '' });
            }
            return hotel_rooms;
         },
         room_types: (s: any) => s.activities.room_types,
         guides(s: any) {
            const guides: any[] = s.activities.guides;
            if (first(guides)?.id) {
               guides.unshift({ id: '', o_ability: '', o_endofemployment: '', o_name: '' });
            }
            return guides;
         },
         agents: (s: any) => s.activities.agents,
         drivers(s: any) {
            const drivers: any[] = s.activities.drivers;
            if (first(drivers)?.id) {
               drivers.unshift({ id: '', o_id: '', o_name: '', o_status: '' });
            }
            return drivers;
         },
         dataReady: (s: any) => s.ready,
      }),
   },
   metaInfo(): MetaInfo {
      return {
         title: '',
         titleTemplate: 'Order info',
      };
   },
   beforeRouteLeave(to, from, next) {
      // @ts-ignore
      if (this.beforeunload !== this.loadBeforeunload) {
         if (!window.confirm('Leave without saving?')) {
            return;
         }
      }

      const favicon: any = document.querySelector("link[rel~='icon']") as HTMLElement;
      favicon.setAttribute('href', process.env.VUE_APP_PUBLIC_PATH + 'favicon.ico');

      next();
   },
   mixins: [colorTooltipsMixin],
})
export default class Action extends BasePage {
   @Prop({ type: [String, Number], default: null }) id!: string;

   readonly new_color: string = 'FF2D00';

   user_roles!: number[];

   activities_colors!: any[];

   payment_colors!: any[];

   addColorTooltips!: any;

   guides!: any[];

   guide_services!: any[];

   activities!: any[];

   currencies!: any[];

   tarification!: any[];

   agents!: any[];

   drivers!: any[];

   dataReady!: boolean;

   room_types!: any[];

   hotel_rooms!: any[];

   filelist: any = [];

   files: any = [];

   requestFields: string[] = [];

   form: OrderActivityInterface = {
      a_files: [],
      o_id: 0,
      o_overal_status: 'FF2D00',
      o_group_color: '1A50FC',
      o_check_in_date: '',
      o_booking_date: '',
      o_lead_guest: '',
      o_stay_in: 0,
      o_self_arranged_accommodation: false,
      o_self_arranged_notes: '',
      o_pax: 1,
      o_our_reference: '',

      o_agent: 0,
      o_agent_reference: '',
      o_agent_email: '',
      o_agent_phone: '',

      o_group_phone: '',
      o_group_email: '',

      o_currency: 526,

      o_responsible_guide: 0,
      o_responsible_guide_2: 0,

      o_service_type: '',
      o_inbound_flight: '',
      o_inbound_date: '',
      o_inbound_time: '',
      o_outbound_flight: '',
      o_outbound_date: '',
      o_outbound_time: '',
      o_welcome_board: '',
      o_notes: '',

      o_activities: [],
      o_extra_saling: [],

      o_accommodation: [],
      o_accommodation_notes: '',
      o_accommodation_forms_notes: '',
      o_accommodation_gp: 0,

      o_total_price_pp: 0,
      o_total_activity_price: 0,
      o_total_activity_cost: 0,
      o_total_cost_pp: 0,
      o_activity_gp: 0,
      o_total_gp: 0,
      o_beneficiary: 0,
      o_account: 0,

      o_per_person_gp: 0,

      o_invoice: [],
      o_invoice_to: '',
      o_invoice_date: '',
      o_invoice_currency: 0,
      o_invoice_vat: 0,

      o_reference: '',
      o_arrive: '',
      o_payment_due: '',
      o_total_amount: 0,

      is_archived: false,
      o_who_archived: '',
   };

   TotalAccommodationPrice: number = 0;

   TotalAccommodationCost: number = 0;

   pageLoaded: boolean = false;

   roomPax: number = 0;

   o_task_list: any[] = [];

   o_task_list_fields: any[] = [
      {
         key: '0',
         label: 'status',
         thAttr: {
            style: 'width: 43px;',
         },
      },
      {
         key: '2',
         label: 'Task',
      },
      {
         key: '3',
         label: '',
         tdAttr: {
            style: 'vertical-align: middle;',
         },
      },
   ];

   o_room_types: any[] = [];

   o_accommodation: any[] = [];

   o_accommodation_fields: any[] = [];

   o_activities: any[] = [];

   o_activities_fields: any[] = [];

   o_extra_saling: any[] = [];

   o_extra_saling_fields: any[] = [
      {
         key: '0',
         label: 'status',
         thAttr: {
            style: 'width: 43px;',
         },
      },
      {
         key: '2',
         label: 'Activity',
         thAttr: {
            style: 'width: 220px !important',
         },
      },
      {
         key: '3',
         label: 'Activity day',
         thAttr: {
            style: 'width: 115px;',
         },
      },
      {
         key: '4',
         label: 'pax',
         thAttr: {
            style: 'width: 53px;',
         },
      },
      {
         key: '5',
         label: 'Fact Pax',
         thAttr: {
            style: 'width: 53px;',
         },
      },
      {
         key: '6',
         label: 'Pick time',
         thAttr: {
            style: 'width: 63px;',
         },
      },
      {
         key: '7',
         label: 'Pickup place',
      },
      {
         key: '8',
         label: 'Cost Danc. €',
         thAttr: {
            style: 'width: 73px;',
         },
      },
      {
         key: '9',
         label: 'Cost €',
         thAttr: {
            style: 'width: 73px;',
         },
      },
      {
         key: '10',
         label: 'Commis. €',
         thAttr: {
            style: 'width: 73px;',
         },
      },
      {
         key: '11',
         label: 'Price €',
         thAttr: {
            style: 'width: 73px;',
         },
      },
      {
         key: '12',
         label: 'Transfer',
      },
      {
         key: '13',
         label: 'Guides notes',
      },
      {
         key: '14',
         label: 'Supplier notes',
      },
      {
         key: '15',
         label: '',
         tdAttr: {
            style: 'vertical-align: middle;',
         },
      },
   ];

   o_invoice_amount: number = 0;

   userLiveTimeout: any = null;

   warningLiveUser: string = '';

   warningLiveUserName: string = '';

   warningLive: boolean = false;

   currencyInvoiceParams: any = {};

   currencyParams: any = {};

   accounts: any[] = [];

   loadBeforeunload: string = '';

   beforeunload: string = '';

   created(): void {
      this.$store.dispatch('interval').then(() => {
         this.getOrder();
         this.currencyParams = currencies['EUR'];

         const currency = this.currencies.filter(c => c.id == this.form.o_currency);

         if (currency.length) {
            this.currencyInvoiceParams = currencies[first(currency).d_name];
         }
      });
   }

   mounted(): void {
      this.o_activities_fields = [
         {
            key: '0',
            label: 'status',
            thAttr: {
               style: 'width: 23px;',
            },
         },
         {
            key: '2',
            label: 'Activity',
            thAttr: {
               style: 'width: 250px !important',
            },
         },
         {
            key: '3',
            label: 'Activity day',
            thAttr: {
               style: 'width: 115px;',
            },
         },
         {
            key: '4',
            label: 'pax',
            thAttr: {
               style: 'width: 53px;',
            },
         },
         {
            key: '5',
            label: 'Fact Pax',
            thAttr: {
               style: 'width: 53px;',
            },
         },
         {
            key: '6',
            label: 'Pick time',
            thAttr: {
               style: 'width: 63px;',
            },
         },
         {
            key: '25',
            label: 'Act. time',
            thAttr: {
               style: 'width: 63px;',
            },
         },
         /*{
           key: '7',
           label: 'Pickup place',
           thAttr: {
              style: 'width: 175px;',
           },
        },*/
         {
            key: '7',
            label: 'Activity Price €',
            thAttr: {
               style: 'width: 63px;',
            },
            isActive: !this.user_roles.includes(38),
         },
         {
            key: '8',
            label: 'Per',
            thAttr: {
               style: 'width: 63px;',
            },
            isActive: !this.user_roles.includes(38),
         },
         {
            key: '9',
            label: 'Total Act. Price €',
            thAttr: {
               style: 'width: 73px;',
            },
            isActive: !this.user_roles.includes(38),
         },
         {
            key: '10',
            label: 'Cost Transf. €',
            thAttr: {
               style: 'width: 73px;',
            },
         },
         {
            key: '11',
            label: 'Cost Guide €',
            thAttr: {
               style: 'width: 73px;',
            },
         },
         {
            key: '12',
            label: 'Cost Extra. €',
            thAttr: {
               style: 'width: 73px;',
            },
         },
         {
            key: '13',
            label: 'Act. Cost €',
            thAttr: {
               style: 'width: 73px;',
            },
            isActive: !this.user_roles.includes(38),
         },
         {
            key: '14',
            label: 'Total Act. Cost €',
            thAttr: {
               style: 'width: 73px;',
            },
            isActive: !this.user_roles.includes(38),
         },
         {
            key: '15',
            label: 'Act. GP €',
            thAttr: {
               style: 'width: 73px;',
            },
            isActive: !this.user_roles.includes(38),
         },
         {
            key: '16',
            label: 'Transf. status',
            thAttr: {
               style: 'width: 23px;',
            },
         },
         {
            key: '17',
            label: 'Transfer',
            thAttr: {
               style: 'width: 150px',
            },
         },
         {
            key: '18',
            label: 'Transfer notes (group)',
            thAttr: {
               //style: 'width: 150px',
            },
         },
         {
            key: '19',
            label: 'Transfer notes (driver)',
            thAttr: {
               //style: 'width: 150px',
            },
         },
         {
            key: '20',
            label: 'Guide status',
            thAttr: {
               style: 'width: 23px;',
            },
         },
         {
            key: '21',
            label: 'Guide',
            thAttr: {
               style: 'width: 150px',
            },
         },
         {
            key: '22',
            label: 'Guides notes',
            thAttr: {
               style: 'width: 150px',
            },
         },
         {
            key: '23',
            label: 'Supplier notes',
            thAttr: {
               style: 'width: 150px',
            },
         },
         {
            key: '26',
            label: 'ACT PAY',
            thAttr: {
               style: 'width: 23px',
            },
         },
         {
            key: '27',
            label: 'TRANS PAY',
            thAttr: {
               style: 'width: 23px',
            },
         },
         {
            key: '28',
            label: 'GUIDE PAY',
            thAttr: {
               style: 'width: 23px',
            },
         },
         {
            key: '24',
            label: '',
            thAttr: {
               style: 'text-align: left;',
            },
            tdAttr: {
               style: 'vertical-align: middle;',
            },
         },
         {
            key: '29',
            label: '',
            tdAttr: {
               style: 'vertical-align: middle;',
            },
         },
      ].filter(f => f?.isActive !== false);

      this.o_accommodation_fields = [
         {
            key: '0',
            label: 'status',
            thAttr: {
               style: 'width: 23px;',
            },
         },
         {
            key: '2',
            label: 'Accommodation',
            thAttr: {
               style: 'width: 200px;',
            },
         },
         {
            key: '3',
            label: 'Number of rooms',
         },
         {
            key: '4',
            label: 'room type',
         },
         {
            key: '5',
            label: 'check-in date',
            thAttr: {
               style: 'width: 114px;',
            },
         },
         {
            key: '6',
            label: 'nts',
         },
         {
            key: '7',
            label: 'price €',
            isActive: !this.user_roles.includes(38),
         },
         {
            key: '8',
            label: 'cost €',
         },
         {
            key: '9',
            label: 'ACC PAY',
         },
         {
            key: '10',
            label: '',
            tdAttr: {
               style: 'vertical-align: middle;',
            },
         },
      ].filter(f => f?.isActive !== false);

      this.textareaEvents();
   }

   onChange() {
      const files = (this.$refs.file as HTMLInputElement).files as any;
      this.filelist = [...files];
   }

   remove(i: number) {
      this.filelist.splice(i, 1);
   }

   dragover(event: any) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
         event.currentTarget.classList.remove('bg-gray-100');
         event.currentTarget.classList.add('bg-green-300');
      }
   }

   dragleave(event: any) {
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
   }

   drop(event: any) {
      event.preventDefault();
      (this.$refs.file as HTMLInputElement).files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
   }

   uploadFiles(event: any) {
      event.preventDefault();
      this.$store.commit('showLoading');
      BookingsApi.uploadFiles(this.form.o_id, { data: this.filelist }).then((data: any) => {
         let allFiles: any = [];
         if (this.files) {
            allFiles = this.files;
         }
         data.map((item: any) => {
            allFiles = [item, ...allFiles];
         });
         this.files = allFiles;
         this.filelist = [];
      });
   }

   downloadFile(file_id: number) {
      const file: any = find(this.files, { file_id });
      downloadBlob(file.file_name, b64toBlob(file.base64));
   }

   deleteFile(file_id: number) {
      this.$store.commit('showLoading');
      BookingsApi.deleteFile(this.form.o_id, file_id)
         .then(response => {
            const item = find(this.files, { file_id });
            const index = this.files.indexOf(item);
            this.files.splice(index, 1);
         })
         .finally(() => {
            this.$store.commit('hideLoading');
         });
   }

   isImage(base64: string) {
      const decode = atob(base64).toLowerCase();
      return (
         decode.indexOf('jfif') !== -1 ||
         decode.indexOf('jpg') !== -1 ||
         decode.indexOf('jpeg') !== -1 ||
         decode.indexOf('png') !== -1 ||
         decode.indexOf('gif') !== -1
      );
   }

   textareaEvents() {
      setTimeout(() => {
         document.querySelectorAll('main textarea').forEach($el => {
            const listener = (event: Event) => {
               const element = event.currentTarget as HTMLTextAreaElement;
               element.style.height = '5px';
               element.style.height = element.scrollHeight + 5 + 'px';
            };
            $el.removeEventListener('input', listener, false);
            $el.addEventListener('input', listener, false);
            $el.dispatchEvent(new Event('input'));
         });
      }, 0);
   }

   beforeDestroy(): void {
      this.$store.commit('showLoading');
      clearInterval(this.userLiveTimeout);
      this.removeBeforeUnloadListener();
   }

   prepareInvoice(invoice: any[] = []) {
      return (invoice || []).map((a: any) => {
         a[1] = a[1].toString().replace(/[^\w]+/g, '');
         a[4] = this.formatterFloat(a[4]);
         a[6] = this.formatterFloat(a[6]);
         return a;
      });
   }

   changeState(object_id: number, state: number) {
      this.$store.commit('showLoading');
      BookingsApi.setObjectStatus(this.form.o_id, object_id, state).then(order => {
         this.prepareOrder(order);
      });
   }

   // onChangeInvoiceState(state: number, invoice: any[]): void {
   //    this.changeState(invoice[0], state);
   // }

   restoreOrder() {
      this.changeState(this.form.o_id, 576);
      this.form.is_archived = false;
   }

   archiveOrder() {
      this.changeState(this.form.o_id, 575);
   }

   newOrder() {
      if (this.beforeunload !== this.loadBeforeunload) {
         if (!window.confirm('Leave without saving?')) {
            return;
         }
      }

      BookingsApi.createOrder().then(({ id }: any) => {
         this.$router.push({ name: 'edit.order', params: { id } });
         this.$store.commit('showLoading');
         BookingsApi.orderOne(parseInt(id), this.requestFields).then((order: any) => {
            this.prepareOrder(order);
         });
      });
   }

   checkActivities() {
      const data = Object.values(this.o_activities)
         .map((value: any) => {
            if (this.isNotEmptyValue(value[2]) && this.isNotEmptyValue(value[3]) && this.isNotEmptyValue(value[6])) {
               return {
                  id: value[2],
                  date: this.formatDate(value[3]),
                  time: value[6],
                  o_id: this.form.o_id,
               };
            }
         })
         .filter(item => item != null);

      if (data.length) {
         this.$store.commit('showLoading');
         ActivitiesApi.checkActivityAvailability(data).then((result: any) => {
            const openBlankPage = Object.values(result).filter((item: any) => typeof item !== 'undefined' && Object.keys(item).length !== 0).length;
            if (openBlankPage > 0) {
               const routeData = this.$router.resolve({ name: 'check', query: { data: JSON.stringify(data) } });
               window.open(routeData.href, '_blank');
            } else {
               this.$bvToast.toast('All correct.', {
                  title: 'Success',
                  variant: 'success',
                  solid: true,
               });
            }
         });
      }
   }

   checkDifferenceDays() {
      let hasNotice: boolean = false;
      let elements: any = [];
      const a: string[] = uniq([
         this.form.o_inbound_date,
         this.form.o_outbound_date,
         ...this.o_accommodation.map(a => a[5]),
         ...this.o_activities.map(a => a[3]),
      ])
         .filter(item => this.isNotEmptyValue(item))
         .map((m: any) => this.formatDate(m));

      const maxDate = new Date(Math.max(...a.map((e: string) => new Date(this.formatDate(e)).valueOf()))).valueOf();
      const minDate = new Date(Math.min(...a.map((e: string) => new Date(this.formatDate(e)).valueOf()))).valueOf();
      const difference = Math.round((maxDate - minDate) / (1000 * 60 * 60 * 24));

      const h = this.$createElement;
      const titleVNode = h('div', { domProps: { innerHTML: '<span class="icon-warning-empty"></span>  NOTICE' } });

      //console.log(a);
      if (a.length && a.every((m: any) => m < this.$moment().format('YYYY-MM-DD'))) {
         hasNotice = true;
         let pastEventHtml = '<h5>Attention - saving a past event.<h5>';
         if (difference > 7) {
            pastEventHtml += '<hr>';
         }
         elements.push(h('div', { domProps: { innerHTML: pastEventHtml } }));
      }

      if (difference > 7) {
         hasNotice = true;
         elements.push([
            h('div', { domProps: { innerHTML: '<h5>There are more than 7 days between the first and last dates.</h5>' } }),
            h('div', ['First date: ' + this.$moment(minDate).format('DD.MM.YYYY')]),
            h('div', ['Last date: ' + this.$moment(maxDate).format('DD.MM.YYYY')]),
         ]);
      }

      const messageVNode = h('div', { class: ['foobar'] }, elements);
      if (hasNotice) {
         this.$bvModal
            .msgBoxConfirm([messageVNode], {
               title: [titleVNode],
               size: 'md',
               buttonSize: 'md',
               okVariant: 'success',
               okTitle: 'SAVE',
               cancelTitle: 'CANCEL',
               footerClass: 'p-2',
               hideHeaderClose: false,
               centered: true,
            })
            .then(value => {
               if (value == true) {
                  this.saveOrder();
               }
            })
            .catch(err => {
               console.log(err);
            });
      } else {
         this.saveOrder();
      }
   }

   async saveOrder() {
      const $errors = document.querySelectorAll('.error, .is-invalid');
      if ($errors.length) {
         $errors.forEach($el => {
            $el.className += ' animate__animated animate__shakeX';
         });

         setTimeout(() => {
            $errors.forEach($el => {
               $el.className = $el.className
                  .replace('animate__animated', '')
                  .replace('animate__shakeX', '')
                  .replace(/[\s]{2,}/g, ' ');
            });
         }, 1500);
         return;
      }

      const doc = new jsPDF({
         orientation: 'landscape',
         format: 'a3',
      });

      const styles = { font: 'Roboto', fontStyle: 'normal', fontSize: 9, overflow: 'linebreak' };

      const margin: any = {
         left: 10,
         top: 10,
      };

      doc.setFont('Roboto', 'normal');
      doc.setFontSize(8);

      let body: any[] = [];

      let head: string[] = [];

      const headStyles: any = {
         ...styles,
         fillColor: '#D5E9FA',
         textColor: '#000000',
         halign: 'left',
         valign: 'middle',
      };

      const bodyStyles: any = {
         ...styles,
         valign: 'middle',
      };

      const __elContent = (el: HTMLInputElement | Element, parent: Element) => {
         let content = (el as HTMLInputElement).value;

         if (el instanceof HTMLSelectElement) {
            content = ((parent as HTMLElement).querySelector('[value="' + content + '"]') as HTMLElement)?.innerText;
         }

         if (el instanceof HTMLInputElement && el.className.indexOf('search') >= 0) {
            content = ((parent as HTMLElement).querySelector('div.text') as HTMLElement)?.innerText;
         }

         if (el instanceof HTMLInputElement && el.type == 'checkbox') {
            content = el.checked ? 'checked' : '';
         }

         if (el.className.indexOf('current-color') >= 0) {
            const color = colorString.get((el as HTMLElement).style.backgroundColor);
            content = colorString.to.hex(color.value);
         }

         if (content && (content.indexOf('#') === 0 || content.indexOf('rgb') === 0)) {
            return {
               content: '_#_',
               styles: { textColor: content, size: 1 },
            };
         }

         return content ? content.trim() : '';
      };

      const __groupContent = (group: Element) => {
         const values: any[] = [];
         group.querySelectorAll('input, select, textarea').forEach(el => {
            values.push(__elContent(el, group));
         });
         return values.join(' ');
      };

      const didDrawCell = (data: any) => {
         const content = (data.cell.raw?.content as string) || '';
         if (data.section === 'body' && content.indexOf('_#_') === 0) {
            let canvas = document.createElement('canvas') as HTMLCanvasElement;
            canvas.width = 8;
            canvas.height = 8;
            let ctx = canvas.getContext('2d') as any;
            ctx.fillStyle = data.cell.styles.textColor;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            doc.addImage(canvas.toDataURL(), 'PNG', data.cell.x + 2, data.cell.y + (data.cell.height / 2 - 6 / 2), 6, 6);
         }
      };

      const addTable = async (selector: string, title: string, el: Element | undefined = undefined) => {
         (el ? el : document).querySelectorAll(selector).forEach(table => {
            head = [];
            body = [];

            table.querySelectorAll('thead th').forEach((th: Element) => {
               head.push((th.querySelector('div, span') as HTMLElement).innerText || '');
            });

            table.querySelectorAll('tbody tr').forEach(tr => {
               const values: any[] = [];
               tr.querySelectorAll('td').forEach((td, i) => {
                  td.querySelectorAll('input, select, textarea, .current-color').forEach(el => {
                     if (elementVisible(el)) {
                        const value = __elContent(el, td);
                        if (!values[i]) {
                           values.push(value);
                        } else if (typeof values[i] == 'string' && typeof value == 'string') {
                           values[i] += ' ' + value;
                        } else {
                           values[i] = value;
                        }
                     }
                  });
               });
               body.push(values);
            });

            if (body.length) {
               autoTable(doc, {
                  // @ts-ignore
                  headStyles,
                  head: [[{ content: title.trim().toUpperCase(), colSpan: head.length, styles: { fontSize: 11, halign: 'center' } }], head],
                  body,
                  bodyStyles,
                  didDrawCell,
                  margin,
               });
            }
         });
      };

      document.querySelectorAll('.first-form, .second-form').forEach((form: Element) => {
         form.querySelectorAll('.form-group').forEach((group: Element) => {
            body.push([
               (group.querySelector('label') as HTMLElement).innerText || '',
               {
                  content: __groupContent(group),
                  styles,
                  ...(group.querySelectorAll('.color-picker').length
                     ? {
                          content: '_#_',
                          styles: { textColor: (group.querySelector('.current-color') as HTMLElement).style.backgroundColor, size: 1 },
                       }
                     : {}),
               },
            ]);
         });
      });

      await autoTable(doc, {
         // @ts-ignore
         headStyles,
         body: body.filter((e, i) => i % 2),
         bodyStyles,
         tableWidth: 125,
         theme: 'plain',
         didDrawCell,
         startY: 10,
         margin: {
            ...margin,
            left: 150,
         },
      });

      await autoTable(doc, {
         // @ts-ignore
         headStyles,
         body: body.filter((e, i) => !(i % 2)),
         bodyStyles,
         tableWidth: 125,
         theme: 'plain',
         didDrawCell,
         startY: 10,
         margin,
      });

      await addTable('.task-list table', 'Task list');

      await addTable('.three-form table', 'Accommodation');

      // activities
      document.querySelectorAll('.activities-tabs .nav-link').forEach((nav: Element) => {
         head = [];
         body = [];

         const link = nav.getAttribute('aria-controls') as string;
         const tab = document.querySelector('.activities-tabs #' + link + '.tab-pane') as HTMLElement;

         addTable('table.table-content', (nav as HTMLElement).innerText, tab);

         tab.querySelectorAll('.sum-details').forEach(div => {
            body = [];

            div.querySelectorAll('table').forEach(table => {
               table.querySelectorAll('tr').forEach((tr, i) => {
                  if (body[i]) {
                     body[i].push((tr.querySelector('th') as HTMLElement).innerText, (tr.querySelector('td') as HTMLElement).innerText);
                  } else {
                     body.push([(tr.querySelector('th') as HTMLElement).innerText, (tr.querySelector('td') as HTMLElement).innerText]);
                  }
               });
            });

            autoTable(doc, {
               body,
               bodyStyles,
               didDrawCell,
               margin,
               theme: 'plain',
            });
         });
      });

      // invoice history
      await addTable('.invoice-history table', 'Invoice history');

      // final

      //doc.save('test-' + '.pdf');
      //
      const filename: any[] = [];

      filename.push(this.form.o_lead_guest);
      filename.push(find(this.agents, { id: this.form.o_agent })?.o_nick);
      filename.push('#' + this.form.o_our_reference);

      const a: any = uniq([
         this.form.o_inbound_date,
         this.form.o_outbound_date,
         ...this.o_accommodation.map(a => a[5]),
         ...this.o_activities.map(a => a[3]),
      ]).filter(item => this.isNotEmptyValue(item));

      if (a.length > 0) {
         const minDate: any = new Date(Math.min(...a.map((e: string) => new Date(this.formatDate(e)))));
         filename.push(this.$moment(minDate).format('DD.MM.YYYY'));
      } else {
         filename.push(this.$moment().format('DD.MM.YYYY'));
      }

      const o_activities = mapValues(this.o_activities, (v: any) => (isNull(v) ? '' : this.formatDate(v))) as any[];
      const o_extra_saling = mapValues(this.o_extra_saling, (v: any) => (isNull(v) ? '' : this.formatDate(v))) as any[];
      const o_accommodation = mapValues(this.o_accommodation, (v: any) => (isNull(v) ? '' : this.formatDate(v))) as any[];

      const data = {
         ...mapValues(this.form, (v: any) => (isNull(v) ? '' : this.formatDate(v))),
         o_accommodation: Object.values(o_accommodation).map((a: any) => {
            a[1] = find(this.activities_colors, { d_color_code: a[0] })?.id ?? '';
            a[9] = find(this.payment_colors, { d_color_code: a[9] })?.id ?? first(this.payment_colors)?.id;

            if (a.hasOwnProperty(10)) {
               delete a[10];
            }

            if (a.hasOwnProperty(11)) {
               delete a[11];
            }

            if (a.hasOwnProperty(12)) {
               delete a[12];
            }

            return a.filter((item: any) => item != null);
         }),
         o_extra_saling: Object.values(o_extra_saling).map((a: any) => {
            a[1] = find(this.activities_colors, { d_color_code: a[0] })?.id ?? '';
            return a;
         }),
         o_activities: Object.values(o_activities)
            .filter((a: any) => a[2] != 0)
            .map((a: any) => {
               a[1] = find(this.activities_colors, { d_color_code: a[0] })?.id ?? '';

               [16, 20].forEach(i => {
                  a[i] = find(this.activities_colors, { d_color_code: a[i] })?.id ?? '';
               });

               [26, 27, 28].forEach(i => {
                  a[i] = find(this.payment_colors, { d_color_code: a[i] })?.id ?? '';
               });

               return a;
            }),
         o_task_list: this.o_task_list.map((a: any) => {
            a[0] = a[0].length ? a[0] : this.new_color;
            a[1] = find(this.activities_colors, { d_color_code: a[0] })?.id ?? '';
            return a;
         }),
         o_invoice_to: this.form.o_agent.toString(),
         o_total_amount: this.updateTotalAmount(true),
         o_pdf_report: {
            filename: filename.join(' ') + '.pdf',
            body: doc.output('datauristring'),
         },
      };

      this.$store.commit('showLoading');

      const order = await BookingsApi.saveOrder(this.form.o_id, data);
      const prepared = order && (await this.prepareOrder(order));

      if (prepared) {
         this.beforeunload = this.createHash();
         this.loadBeforeunload = this.createHash();
         this.removeBeforeUnloadListener();
      }

      this.$store.commit('hideLoading');
   }

   getOrder() {
      if (!this.dataReady) {
         setTimeout(() => this.getOrder(), 1000);
         return;
      }

      if (this.id) {
         this.pageLoaded = false;
         this.$store.commit('showLoading');

         BookingsApi.orderOne(parseInt(this.id))
            .then(response => {
               this.prepareOrder(response);

               if (typeof this.form.o_lead_guest.split(' ')[1] != 'undefined') {
                  const titleEl: any = document.querySelector('head title');
                  titleEl.textContent = this.form.o_lead_guest.split(' ')[1] + ' - ' + titleEl.textContent;
               }

               this.files = response['a_files'];
               this.addColorTooltips(['.activities-tabs', '.three-form', '.task-list'], this.activities_colors);
               this.addColorTooltips(['.activities-tabs', '.three-form'], this.payment_colors, 'pay');
               this.userLiveTimeout = setInterval(() => this.checkUserPresenceInOrder(), 10000);
            })
            .finally(() => {
               this.$store.commit('hideLoading');
               this.pageLoaded = true;
            });
      }
   }

   checkUserPresenceInOrder() {
      const params = {
         group_id: parseInt(this.id),
         username: localStorage.getItem('username'),
      };

      BookingsApi.checkUserPresence(params).then((response: any) => {
         if (response.username != params.username) {
            this.warningLiveUserName = response.username;
            this.warningLiveUser = `This card is edited by ${response.username}`;
            this.warningLive = true;
         } else if (this.warningLive) {
            this.warningLiveUser = 'This card has been edited by ' + this.warningLiveUserName;
         }
      });
   }

   async prepareOrder(order: any) {
      if (typeof order.o_task_list != 'undefined') {
         if (Array.isArray(order.o_task_list) && order.o_task_list.length) {
            this.o_task_list = order.o_task_list
               .filter((a: any) => a.length >= 3)
               .map((a: any) => {
                  a[0] = a[0].toString();
                  a[0] = a[0].length ? a[0] : this.new_color;
                  a[1] = find(this.activities_colors, { d_color_code: a[0] })?.id ?? '';
                  return a;
               });
         } else {
            this.o_task_list = [];
         }
      }

      if (typeof order.o_accommodation != 'undefined') {
         if (Array.isArray(order.o_accommodation) && order.o_accommodation.length) {
            this.o_accommodation = sortBy(
               order.o_accommodation.filter((a: any) => a.length >= 9).map((a: any) => this.prepareAccommodation(a)),
               [5, 1]
            );
         } else {
            this.o_accommodation = [];
         }
      }

      if (typeof order.o_activities != 'undefined') {
         if (Array.isArray(order.o_activities) && order.o_activities.length) {
            const o_activities = order.o_activities
               .filter((a: any) => a.length >= 19)
               .map((a: any, i: number) => this.prepareActivityItem(a, 'o_activities', i));

            this.o_activities = o_activities.map((a: any) => {
               for (const i of [1, 16, 20]) {
                  a[i] = find(this.activities_colors, { id: this.parseIntCustom(a[i]) })?.d_color_code ?? this.new_color;
               }

               for (const i of [26, 27, 28]) {
                  a[i] = find(this.payment_colors, { id: this.parseIntCustom(a[i]) })?.d_color_code ?? this.new_color;
               }

               return a;
            });
         } else {
            this.o_activities = [];
         }
      }

      order = this.updateDates(order, [
         'o_check_in_date',
         'o_inbound_time',
         'o_outbound_time',
         'o_arrive',
         'o_invoice_date',
         'o_booking_date',
         'o_inbound_date',
         'o_outbound_date',
      ]);

      if (typeof order.o_extra_saling != 'undefined') {
         if (Array.isArray(order.o_extra_saling) && order.o_extra_saling.length) {
            this.o_extra_saling = order.o_extra_saling
               .filter((a: any) => a.length >= 10)
               .map((a: any, i: number) => this.prepareActivityItem(a, 'o_extra_saling', i));
         } else if (order.o_extra_salin && order.o_extra_saling == null) {
            this.o_extra_saling = [];
         }
      }

      this.form = {
         ...this.form,
         ...order,
         o_invoice_vat: 1113,
         o_arrive: order.o_check_in_date,
         o_invoice_date: this.$moment().format('YYYY-MM-DD'),
         o_payment_due: this.$moment()
            .add(7, 'd')
            .format('YYYY-MM-DD'),
      };

      const maxPax = Math.max(...this.o_activities.map((a: any) => a[4]));

      if (order.o_pax) {
         this.form = {
            ...this.form,
            o_pax: maxPax > Math.max(0, parseInt(order.o_pax)) ? maxPax : Math.max(0, parseInt(order.o_pax)),
         };
      }

      if (order.o_currency && order.o_invoice_currency) {
         this.form = {
            ...this.form,
            o_invoice_currency: !Number.isInteger(order.o_invoice_currency) ? 526 : order.o_invoice_currency,
            o_currency: typeof order.o_currency != 'number' || !Number.isInteger(order.o_currency) ? 526 : order.o_currency,
         };
      }

      if (order.o_invoice && order.o_invoice.length) {
         const o_invoice = this.prepareInvoice(order.o_invoice);
         this.form = { ...this.form, o_invoice };

         this.o_invoice_amount = (this.$options.filters as any).reduce_sum(
            o_invoice.filter(a => a[8] !== 102),
            4
         );
      }

      if (order.o_agent) {
         this.changeOAgent(order.o_agent);
      }

      return true;
   }

   updateForm(form: any) {
      this.form = { ...this.form, ...form };
   }

   updateDates(order: any, values: string[]) {
      for (let value of values) {
         if (typeof order[value] == 'number' && order[value] != '') {
            if (['o_inbound_time', 'o_outbound_time'].includes(value)) {
               order[value] = this.formatTime(order[value]);
            } else {
               order[value] = this.formatterDate(order[value], value == 'o_check_in_date' ? 'unix' : null);
            }
         }
      }
      return order;
   }

   removeActivity(data: any, column: string = 'o_activities') {
      // @ts-ignore
      this[column] = [...(this[column] || [])].filter((a, i) => i !== data.index);
   }

   getCostTransf(params: any) {
      return ActivitiesApi.getCostTransf(params).then((result: any) => {
         if (result.cost_transfer != 'ERROR' && result.cost_transfer != null) {
            return result.cost_transfer;
         }
      });
   }

   getAccommodationsData(params: any) {
      return HotelsApi.getAccommodationsData(params).then((result: any) => {
         if (result) {
            return result;
         }
      });
   }

   getAccommodationsRoomType(params: AccommodationsRoomTypeParams) {
      return HotelsApi.getAccommodationsRoomType(params).then((result: any) => {
         if (result) {
            return result;
         }
      });
   }

   isNotEmptyValue(value: any) {
      return value !== null && value !== '' ? value : false;
   }

   async updateActivityValue($event: any, data: any, column: string = 'o_activities') {
      if (!this.pageLoaded) return;

      // @ts-ignore
      let items = [...this[column]];
      let value = typeof $event.target !== 'undefined' && typeof $event.target.value !== 'undefined' ? $event.target.value : $event;
      let item = [...data.item];

      item[data.field.key] = typeof value == 'number' && isNaN(value) ? 0 : value;

      if (column == 'o_activities') {
         if (item[2] !== items[data.index][2]) {
            const o_type = find(this.activities, { id: item[2] })?.o_type;
            const o_hotaddress = find(this.hotel_rooms, { id: this.form.o_stay_in });
            item[23] = o_type == 1228 && o_hotaddress ? o_hotaddress['o_hotel.o_hotaddress'] : '';
         }

         if (item[17] !== items[data.index][17] || ['2', '3', '4'].includes(data.field.key)) {
            if (this.isNotEmptyValue(item[2]) && this.isNotEmptyValue(item[4]) && this.isNotEmptyValue(item[17])) {
               const cost_transfer = await this.getCostTransf({
                  id: item[2],
                  pax: item[4],
                  supplier_id: item[17],
                  activity_day: item[3],
               });
               item[10] = cost_transfer ? cost_transfer : 0;
            } else {
               item[10] = 0;
            }
         }
      }

      if (['o_activities', 'o_extra_saling'].includes(column)) {
         if ('o_activities' === column && data.field.key == '2') {
            const activity = find(this.activities, { id: value });
            if (item[24] != 1) {
               item[7] = activity.o_lsp;
               item[8] = activity['o_perr.d_id'];
               item[13] = 0;
               item[18] = item[18] || activity['o_transfer_notes_group'];
            }
         }
         item = this.prepareActivityItem(item, column, data.index);
      }

      // todo! refactoring neeaded
      if (['o_accommodation'].includes(column)) {
         if (
            item[2] !== items[data.index][2] ||
            item[3] !== items[data.index][3] ||
            item[4] !== items[data.index][4] ||
            item[5] !== items[data.index][5] ||
            item[6] !== items[data.index][6]
         ) {
            if (item[2] !== items[data.index][2] && this.isNotEmptyValue(item[2])) {
               const response = await this.getAccommodationsRoomType({ accommodation: item[2] });
               item[10] = this.room_types.filter(a => Object.values(response).includes(a.id.toString()));
            }

            if (
               this.isNotEmptyValue(item[2]) &&
               this.isNotEmptyValue(item[3]) &&
               this.isNotEmptyValue(item[4]) &&
               this.isNotEmptyValue(item[5]) &&
               this.isNotEmptyValue(item[6])
            ) {
               const response = await this.getAccommodationsData({
                  accommodation: item[2],
                  number_of_rooms: item[3],
                  room_type: item[4],
                  check_in: item[5],
                  nts: item[6],
               });

               if (this.isNotEmptyValue(response.price)) {
                  item[7] = response.price;
               }

               if (this.isNotEmptyValue(response.cost)) {
                  item[8] = response.cost;
               }

               if (this.isNotEmptyValue(response['cost per room'])) {
                  item[11] = response['cost per room'];
               }

               if (this.isNotEmptyValue(response['price per room'])) {
                  item[12] = response['price per room'];
               }
            }

            item = this.prepareActivityItem(item, column, data.index);
         }
      }

      const compare = md5(JSON.stringify(items[data.index])) !== md5(JSON.stringify(item));
      if (compare) {
         items[data.index] = item;

         switch (column) {
            case 'o_activities':
               this.o_activities = [...items];
               break;
            case 'o_extra_saling':
               this.o_extra_saling = [...items];
               break;
            case 'o_task_list':
               this.o_task_list = [...items];
               break;
            case 'o_accommodation':
               this.o_accommodation = [...items];
               break;
         }
      }
   }

   prepareActivityItem(a: any[], column: string = 'o_activities', index: number | null = null) {
      a[7] = a[7].toString(); // Pickup place

      if (column == 'o_activities') {
         a[3] = this.formatterDate(a[3]);
         a[24] = parseInt(a[24]);

         const activity = find(this.activities, { id: this.parseIntCustom(a[2]) });
         if (activity && a[24] !== 1) {
            if (a[7] == 0) {
               a[7] = activity.o_lsp;
            }
            if (a[13] == 0) {
               a[13] = activity.o_cost;
            }
         }

         const actDate = new Date(
            a[3]
               .split('.')
               .reverse()
               .join('-')
         );

         let oListmingroup: any = null;

         if (activity) {
            const oPriceFrom = new Date(this.formatterDate(activity.o_price_from));
            const costFrom = new Date(this.formatterDate(activity.o_cost_from));
            if (a[24] !== 1) {
               if (activity.o_price_from != '' && actDate >= oPriceFrom) {
                  a[7] = activity.o_new_lsp != '' && activity.o_new_lsp != 0 ? activity.o_new_lsp : activity.o_lsp;
                  oListmingroup = activity.o_new_minimum != '' && activity.o_new_minimum != 0 ? activity.o_new_minimum : activity.o_listmingroup;
               } else {
                  a[7] = activity.o_lsp;
                  oListmingroup = activity.o_listmingroup;
               }

               if (activity.o_cost_from != '' && activity.o_new_supp_cost != '' && actDate >= costFrom) {
                  a[13] = activity.o_new_supp_cost;
               } else {
                  a[13] = activity.o_cost;
               }
            }
         }

         a[6] = a[6] != 0 ? this.formatTime(a[6]) : '';
         a[25] = a[25] != 0 ? this.formatTime(a[25]) : '';

         a[8] = a[8] != 0 ? this.parseIntCustom(a[8]) : ''; // Per
         if (activity) {
            const pax = Math.max(1, parseInt(a[4] ? a[4] : 0), parseInt(activity && oListmingroup ? oListmingroup : 0));
            const pax_cost = Math.max(1, parseInt(a[4] ? a[4] : 0), parseInt(activity && activity.o_minsize ? activity.o_minsize : 0));
            if (a[8] === 0) {
               a[8] = activity['o_perr.d_id'];
            }
            // Total Act. Price €
            if (a[8] == 109) {
               a[9] = this.parseFloatCustom(a[7]) * pax;
            } else {
               a[9] = a[7];
            }
            // END Total Act. Price €

            let o_cost = this.parseFloatCustom(a[13]);

            const o_perr = find(this.activities, { id: a[2] }) as any;
            // ACT. COST €
            if (['PP'].includes(o_perr['o_per.d_name'])) {
               o_cost = this.parseFloatCustom(a[13]) * pax_cost;
            }

            if (parseFloat(activity.o_additional_cost_pp) > 0) {
               o_cost += this.parseFloatCustom(activity.o_additional_cost_pp) * pax_cost;
            }

            if (parseFloat(activity.o_additional_cost) > 0) {
               o_cost += this.parseFloatCustom(activity.o_additional_cost);
            }

            if (a[24] !== 1) {
               a[13] = o_cost;
            }

            a[14] = this.parseFloatCustom(a[10]) + this.parseFloatCustom(a[11]) + this.parseFloatCustom(a[12]) + this.parseFloatCustom(a[13]); // Total Act. cost €
            a[15] = this.parseFloatCustom(a[9]) - this.parseFloatCustom(a[14]); // Act. GP €
         }

         for (const i of [7, 9, 10, 11, 12, 13, 14, 15]) {
            a[i] = this.formatterFloat(a[i]);
         }

         this.updateTotalAmount();
      } else {
         if (column != 'o_accommodation') {
            a[8] = this.parseFloatCustom(a[8]); // Cost Danc. €
         }
      }

      if (column == 'o_extra_saling') {
         a[3] = this.formatterDate(a[3]);
         a[6] = this.formatTime(a[6]);

         for (const i of [8, 9, 10, 11]) {
            a[i] = this.formatterFloat(a[i]);
         }
      }

      return column == 'o_accommodation' ? a : a.map(v => (typeof v !== 'string' && isNaN(v) ? 0 : v));
   }

   prepareAccommodation(a: any[] = []) {
      a[0] = a[0].toString();
      a[0] = a[0].length ? a[0] : this.new_color;
      a[3] = this.parseIntCustom(a[3]);
      a[5] = this.formatterDate(a[5]);
      a[6] = this.parseIntCustom(a[6]);
      a[7] = this.formatterFloat(a[7] || 0);
      a[8] = this.formatterFloat(a[8] || 0);
      a[9] = first(this.payment_colors.filter(c => [c.d_color_code, c.id, c.id.toString()].includes(a[9])))?.d_color_code || this.new_color;

      for (let i of [3, 6]) {
         a[i] = Number.isNaN(a[i]) ? 0 : a[i];
      }

      return a;
   }

   @Watch('form.o_invoice', { deep: true })
   updateTotalAmount(rNumber: boolean = false) {
      const totalInvoiceAmount = (this.$options.filters as any).reduce_sum(
         this.form.o_invoice.filter(item => [58, 66, 101, 297].includes(this.parseIntCustom(item[8]))),
         4
      );

      const o_total_amount = (this.form.o_total_activity_price + this.TotalAccommodationPrice - totalInvoiceAmount).toFixed(2);

      if (rNumber) {
         return o_total_amount;
      }

      this.form = { ...this.form, o_total_amount: this.parseIntCustom(o_total_amount) };
   }

   addActivity() {
      const items = this.o_activities || [];
      items.push(this.newActivity(items.length + 1));
      this.o_activities = items.filter(sub => sub.length);
      this.addColorTooltips(['.activities-tabs'], this.activities_colors);
      this.addColorTooltips(['.activities-tabs'], this.payment_colors, 'pay');
   }

   newActivity(index: number, activity: number = 0, activityDate: string = '', pickTime: string = ''): any[] {
      const color = find(this.activities_colors, { d_color_code: 'FF2D00' });
      return this.prepareActivityItem(
         [
            color.d_color_code,
            color.id,
            activity,
            activityDate,
            this.form.o_pax,
            '',
            pickTime,
            '',
            0,
            '',
            0,
            0,
            0,
            0,
            0,
            '',
            color.d_color_code,
            '',
            '',
            '',
            color.d_color_code,
            '',
            '',
            '',
            0,
            0,
            this.new_color,
            this.new_color,
            this.new_color,
         ],
         'o_activities',
         index
      );
   }

   addExtraSaling() {
      //console.log('addExtraSaling');
      const items = this.o_extra_saling || [];
      const color = first(this.activities_colors);
      //const activity = first(this.activities);
      items.push(
         this.prepareActivityItem(
            [
               color.d_color_code,
               color.id,
               0,
               '',
               this.form.o_pax,
               '',
               '', // Pick time
               '', // Pickup place
               0,
               0,
               0,
               0,
               '', // Transfer
               '',
               '',
            ],
            'o_extra_saling',
            items.length + 1
         )
      );

      this.o_extra_saling = items.filter(sub => sub.length);
   }

   addRoom() {
      const color = find(this.activities_colors, { d_color_code: 'FF2D00' });
      const payColor = find(this.payment_colors, { d_color_code: 'FF2D00' });
      this.o_accommodation = [
         ...(this.o_accommodation || []),
         [color.d_color_code, color.id, '', Math.max(0, this.form.o_pax - this.roomPax), 0, null, 1, 0, 0, payColor.d_color_code],
      ];
      this.addColorTooltips(['.three-form'], this.activities_colors);
      this.addColorTooltips(['.three-form'], this.payment_colors, 'pay');
   }

   addTask() {
      const color = first(this.activities_colors);
      this.o_task_list = [...(this.o_task_list || []), [color.d_color_code, color.id, '']];
      this.addColorTooltips(['.task-list'], this.activities_colors);
   }

   onChangeFormListener(event: any) {
      event.preventDefault();
      event.returnValue = '';
   }

   updateFormValue(value: string) {
      switch (value) {
         case 'o_inbound_date':
            this.form.o_arrive = this.form.o_inbound_date as string;
            break;

         case 'o_lead_guest':
            this.form.o_reference = this.form.o_lead_guest;
            break;
      }
   }

   createHash(form: any = {}): string {
      const data = {
         ...this.form,
         ...form,
         o_total_gp: null,
         o_total_price_pp: null,
         o_total_activity_price: null,
         o_total_activity_cost: null,
         o_total_cost_pp: null,
         o_reference: '',
         o_total_amount: '',
         o_invoice: [],
         o_invoice_currency: null,
         o_invoice_date: null,
         o_invoice_to: null,
         o_invoice_vat: null,
         o_payment_due: '',
         o_lead_guest: null,
         o_activity_gp: null,
         o_accommodation_gp: null,
         o_per_person_gp: null,
         o_activities: this.o_activities,
         o_extra_saling: this.o_extra_saling,
         o_task_list: this.o_task_list,
         o_accommodation: this.o_accommodation,
      };
      return md5(JSON.stringify(data));
   }

   removeBeforeUnloadListener() {
      const favicon: any = document.querySelector("link[rel~='icon']") as HTMLElement;
      favicon.setAttribute('href', process.env.VUE_APP_PUBLIC_PATH + 'favicon.ico');

      window.removeEventListener('beforeunload', this.onChangeFormListener, false);
   }

   addBeforeUnloadListener() {
      const favicon: any = document.querySelector("link[rel~='icon']") as HTMLElement;
      favicon.setAttribute('href', process.env.VUE_APP_PUBLIC_PATH + 'favicon-dot.ico');

      window.addEventListener('beforeunload', this.onChangeFormListener, false);
   }

   prefillInvoice() {
      this.form.o_reference = this.form.o_agent_reference + ' ' + this.form.o_lead_guest + ' Our reference: ' + this.form.o_our_reference;
   }

   @Watch('form', { deep: true, immediate: false })
   changeForm(n: OrderActivityInterface) {
      this.beforeunload = this.createHash(n);
      this.prefillInvoice();
      this.textareaEvents();
   }

   @Watch('pageLoaded')
   onChangePageLoaded(pageLoaded: boolean) {
      if (pageLoaded) {
         this.loadBeforeunload = this.createHash(this.form);
      } else {
         this.removeBeforeUnloadListener();
      }
   }

   @Watch('beforeunload')
   @Watch('loadBeforeunload')
   changeBeforeUnload() {
      if (this.beforeunload.length && this.beforeunload !== this.loadBeforeunload) {
         this.addBeforeUnloadListener();
      } else {
         this.removeBeforeUnloadListener();
      }

      this.checkFormStatus();
   }

   @Watch('form.o_agent')
   changeOAgent(id: number) {
      const agent = find(this.agents, { id });

      const email = agent?.o_itinerary_email ?? agent?.o_agent_email;

      this.form = {
         ...this.form,
         o_invoice_to: agent?.o_name,
         o_agent_email: this.isNotEmptyValue(email) ? email : this.form.o_agent_email,
      };
   }

   @Watch('form.o_currency')
   changeOCurrency(o_invoice_currency: number) {
      const currency = this.currencies.filter(c => c.id == o_invoice_currency);

      if (currency.length) {
         this.currencyInvoiceParams = currencies[first(currency).d_name];
      }

      if (o_invoice_currency !== this.form.o_invoice_currency) {
         this.form = { ...this.form, o_invoice_currency };
      }
   }

   @Watch('form.o_invoice_currency')
   changeOInvoiceCurrency(o_currency: number) {
      if (o_currency !== this.form.o_currency) {
         this.form = { ...this.form, o_currency };
      }
   }

   @Watch('o_accommodation', { immediate: false, deep: true })
   changeOAccommodation(n: any[], o: any[]) {
      const o_accommodation = n.map(a => this.prepareAccommodation(a));

      this.TotalAccommodationPrice = (this.$options.filters as any).reduce_sum(o_accommodation, 7);
      this.TotalAccommodationCost = (this.$options.filters as any).reduce_sum(o_accommodation, 8);
      this.roomPax = (this.$options.filters as any).reduce_sum(o_accommodation, 3);

      const o_accommodation_gp = this.TotalAccommodationPrice - this.TotalAccommodationCost;
      const o_total_gp = this.form.o_activity_gp + o_accommodation_gp;

      if (JSON.stringify(o_accommodation) !== JSON.stringify(o)) {
         this.o_accommodation = o_accommodation;
         this.form = { ...this.form, o_accommodation_gp, o_total_gp };
      }

      this.checkFormStatus();
   }

   @Watch('o_extra_saling', { immediate: false, deep: true })
   changeOExtraSaling(n: any[], o: any[]) {
      //console.log('changeOExtraSaling');
      const o_extra_saling = n.map((a, i) => this.prepareActivityItem(a, 'o_extra_saling', i));
      if (md5(JSON.stringify(o)) !== md5(JSON.stringify(o_extra_saling))) {
         this.o_extra_saling = o_extra_saling;
      }

      this.changeForm(this.form);
   }

   @Watch('o_activities', { immediate: false, deep: true })
   changeOActivities(n: any[], o: any[]) {
      //console.log('changeOActivities');
      const o_activities = n.map((a, i) => this.prepareActivityItem(a, 'o_activities', i));
      if (md5(JSON.stringify(o_activities)) !== md5(JSON.stringify(o))) {
         this.o_activities = o_activities;
      }

      this.checkFormStatus();
   }

   @Watch('o_task_list', { immediate: false, deep: true })
   checkFormStatus() {
      const c = uniq([
         ...this.o_activities.map(a => a[0]),
         ...this.o_activities.map(a => a[16]),
         ...this.o_activities.map(a => a[20]),
         ...this.o_task_list.map(a => a[0]),
         ...this.o_accommodation.map(a => a[0]),
      ]);

      const filteredStatuses = c.filter(function(value, index, arr) {
         return (value = value !== '0F0F0F' && value !== '00A416');
      });

      const o_overal_status =
         c.length > 0 && filteredStatuses.length == 0
            ? '00A416'
            : c.length >= 0 && this.form.o_overal_status != 'FF2D00'
            ? 'FF2D00'
            : this.form.o_overal_status;

      const o_activity_gp = (this.$options.filters as any).reduce_sum(this.o_activities, 15);
      const o_total_activity_price = (this.$options.filters as any).reduce_sum(this.o_activities, 9);
      const o_total_activity_cost = (this.$options.filters as any).reduce_sum(this.o_activities, 14);
      const o_total_gp = o_activity_gp + this.form.o_accommodation_gp;
      const o_accommodation_gp = this.TotalAccommodationPrice - this.TotalAccommodationCost;
      const o_per_person_gp = o_total_gp / this.form.o_pax;
      const o_total_price_pp = (o_total_activity_price + this.TotalAccommodationPrice) / this.form.o_pax;
      const o_total_cost_pp = (o_total_activity_cost + this.TotalAccommodationCost) / this.form.o_pax;

      if (
         o_activity_gp !== this.form.o_activity_gp ||
         o_total_activity_price !== this.form.o_total_activity_price ||
         o_total_activity_cost !== this.form.o_total_activity_cost ||
         o_total_cost_pp !== this.form.o_total_cost_pp ||
         o_total_gp !== this.form.o_total_gp ||
         o_total_price_pp !== this.form.o_total_price_pp ||
         o_per_person_gp !== this.form.o_per_person_gp ||
         o_overal_status !== this.form.o_overal_status ||
         o_accommodation_gp !== this.form.o_accommodation_gp
      ) {
         this.form = {
            ...this.form,
            o_activity_gp,
            o_total_activity_price,
            o_total_activity_cost,
            o_total_cost_pp,
            o_total_gp,
            o_total_price_pp,
            o_per_person_gp,
            o_overal_status,
            o_accommodation_gp,
         };
      }

      this.changeForm(this.form);
   }
}
