import { Vue } from 'vue-property-decorator';

import {
   formatterDate,
   formatterTime,
   formatterFloat,
   formatterNumber,
   formatDate,
   formatTime,
   parseFloatCustom,
   parseIntCustom,
   customFormatter,
} from '@/config/functions';

export default class BasePage extends Vue {
   customFormatter: object = customFormatter;

   formatterFloat = formatterFloat;

   formatterNumber = formatterNumber;

   formatterTime = formatterTime;

   formatterDate = formatterDate;

   formatDate = formatDate;

   formatTime = formatTime;

   parseFloatCustom = parseFloatCustom;

   parseIntCustom = parseIntCustom;
}
